// App.tsx

//import { AudioPlayer } from "./components/AudioPlayer";
import ReactPlayer from 'react-player';

// const audio = {
//   url: "https://storage.googleapis.com/media-session/elephants-dream/the-wires.mp3",
//   title: "A sample audio title",
//   author: "The Elephants Dream",
//   thumbnail:
//     "https://mbinc-bucket-demo1.s3.ca-central-1.amazonaws.com/public-assets/mo-graph-01.png",
//     // "https://images.unsplash.com/photo-1511379938547-c1f69419868d",
// };

const App = ()=> {
  return (
    <div className=" mx-auto text-center bg-blue-700">
      {/* <div className="md:w-1/2 lg:w-1/3 mx-auto">
        <AudioPlayer
          url={audio.url}
          title={audio.title}
          author={audio.author}
          thumbnail={audio.thumbnail}
        />
      </div> */}
      <div className="w-full  ">
        <ReactPlayer 
            playing={true}
            loop={true}
            className='z-5 bg-black overflow-hidden'
            url={'/mbtech-animation-clip.mp4'}
            width='100%' 
            height='100%'
            played={false}
            controls={true}
          />
        <div className="z-15 absolute top-1 left-20 ">
          <h1 className="text-xl md:text-2xl lg:text-4xl xl:text-5xl text-green-800">
            Welcome to/Bienvenue chez <span className="text-blue-600">Mario Boisvert Technologies</span>
          </h1>
          <div className="z-15 absolute left-2 items-start justify-start">
            <h1 className="text-lg md:text-xl lg:text-2xl xl:text-3xl text-green-600 justify-start">
              Stay tuned, the site will come online soon!
            </h1>
            <h1 className="text-lg md:text-xl lg:text-2xl xl:text-3xl text-teal-600">
              Demeurez à l'écoute, le site sera disponible prochainement!
            </h1>
          </div>
        </div>  
      </div>
    </div>
  );
}

export default App;
